<script>
import { required, email } from "vuelidate/lib/validators";
import router from '../../../router/index'
import axios from "axios";

import {
    authMethods,
    authFackMethods,
    notificationMethods
} from "@/state/helpers";

export default {
    data() {
        return {
            email: "",
            password: "",
            submitted: false,
            loginError: "",
        };
    },
    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },
    created() {
        document.body.classList.add("auth-body-bg");
    },
    validations: {
        email: { required, email },
        password: { required }
    },
    methods: {
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,
        // Try to log the user in with the username
        // and password they provided.
        async tryToLogIn() {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                const { email, password } = this;
                if (email && password) {
                    const loginResult = await axios.post(`${process.env.VUE_APP_APIURL}/login`, {
                        email, password
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });

                    if (loginResult['data']['code'] === 'success') {
                        const user = {};
                        // login successful if there's a jwt token in the response
                        user.name = loginResult['data']['data']['profile']['name'];
                        user.token = loginResult['data']['data']['token'];
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('user', JSON.stringify(user));
                        this.retrieveGeneralFromDB();
                        router.push('/');
                    } else {
                        if (loginResult['data']['code'] == 'invalid_email' || 
                            loginResult['data']['code'] == 'invalid_password' ||
                            loginResult['data']['code'] == 'account_inactive') {
                            this.loginError = "Invalid login.";
                        }
                    }
                    // this.login({ email, password });
                }
            }
        }
    }
};
</script>

<template>
    <div>
        <div class="home-btn d-none d-sm-block">
            <a href="/">
                <i class="mdi mdi-home-variant h2 text-white"></i>
            </a>
        </div>
        <div>
            <div class="container-fluid p-0">
                <div class="row no-gutters">
                    <div class="col-lg-4">
                        <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div class="w-100">
                                <div class="row justify-content-center">
                                    <div class="col-lg-9">
                                        <div>
                                            <div class="text-center">
                                                <div>
                                                    <a href="/" class="logo">
                                                        <img src="@/assets/images/logo.png" height="45" alt="logo" />                                                        
                                                    </a>
                                                </div>

                                                <h4 class="font-size-18 mt-4">Welcome Back !</h4>
                                                <p class="text-muted">Sign in to continue.</p>
                                            </div>

                                            <b-alert variant="danger" class="mt-3" v-if="notification.message" show
                                                dismissible>{{ notification.message }}</b-alert>

                                            <div class="p-2 mt-5">
                                                <form class="form-horizontal" @submit.prevent="tryToLogIn">
                                                    <div class="form-group auth-form-group-custom mb-4">
                                                        <i class="ri-mail-line auti-custom-input-icon"></i>
                                                        <label for="email">Email</label>
                                                        <input type="email" v-model="email" class="form-control" id="email"
                                                            placeholder="Enter email"
                                                            :class="{ 'is-invalid': (submitted && $v.email.$error) || (submitted && loginError) }" />
                                                        <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                                                            <span v-if="!$v.email.required">Email is required.</span>
                                                            <span v-if="!$v.email.email">Please enter valid email.</span>
                                                        </div>
                                                        <div v-else-if="submitted && loginError" class="invalid-feedback">
                                                            <span>{{ loginError }}</span>
                                                        </div>
                                                    </div>

                                                    <div class="form-group auth-form-group-custom mb-4">
                                                        <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                                        <label for="userpassword">Password</label>
                                                        <input v-model="password" type="password" class="form-control"
                                                            id="userpassword" placeholder="Enter password"
                                                            :class="{ 'is-invalid': (submitted && $v.email.$error) || (submitted && loginError) }" />
                                                        <div v-if="submitted && !$v.password.required"
                                                            class="invalid-feedback">Password is required.
                                                        </div>
                                                        <div v-else-if="submitted && loginError" class="invalid-feedback">
                                                            <span>{{ loginError }}</span>
                                                        </div>
                                                    </div>


                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="customControlInline" />
                                                        <label class="custom-control-label"
                                                            for="customControlInline">Remember me</label>
                                                    </div>

                                                    <div class="mt-4 text-center">
                                                        <button class="btn btn-primary w-md waves-effect waves-light"
                                                            type="submit">Log In</button>
                                                    </div>

                                                    <div class="mt-4 text-center">
                                                        <router-link tag="a" to="/forgot-password" class="text-muted">
                                                            <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                                                        </router-link>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="authentication-bg">
                            <div class="bg-overlay"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>